/*
 * axios 接口请求函数
 */
import {fetch} from '../fetch'; // 引入fetch.js
import api from '../url'; // 引入url.js
import qs from 'qs'; // 引入qs

/*
 * /views/main/login 登录
 * *********************************************************************************************************************
 */
// 获取所有区划
export function getTenantList(params) {
	return fetch({
		url: api.getTenantList,
		method: 'get',
		params: params
	});
}

// 登录
export function oauthToken(data) {
    return fetch({
		url: api.oauthToken,
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Basic enRid2ViOjEyMw==',
		},
		data: qs.stringify(data) // 将参数放在对象中并序列化
    });
}

// 单点登录
export function oauthLoginCode(data) {
    return fetch({
		url: api.oauthLoginCode,
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Basic enRid2ViOjEyMw==',
		},
		data: qs.stringify(data) // 将参数放在对象中并序列化
    });
}

/*
 * /views/main/register 注册
 * *********************************************************************************************************************
 */
// 获取主体类型Map-所有
export function getBusinessPlatforms(params) {
	return fetch({
		url: api.getBusinessPlatforms,
		method: 'get',
		params: params
	});
}

// 注册-招商代理注册
export function RegisterAddUser(data) {
    return fetch({
		url: api.RegisterAddUser,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 注册-校验手机号
export function CheckRegisterPhone(data) {
    return fetch({
		url: api.CheckRegisterPhone,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 注册-企业信用代码
export function checkRegisterCreditCodeExist(data) {
    return fetch({
		url: api.checkRegisterCreditCodeExist,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 获取验证码/发送验证码
export function RegisterSendCode(data) {
    return fetch({
		url: api.RegisterSendCode,
		method: 'post',
		data: JSON.stringify(data)
    });
}

